html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
}

html.notwebp.notavif {
    background: url(../img/background.png) no-repeat top right fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
html.webp.notavif {
    background: url(../img/background.webp) no-repeat top right fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
html.avif {
    background: url(../img/background.avif) no-repeat top right fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

.hidden,
[hidden] {
    display: none !important;
}

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.sr-only.focusable:active,
.sr-only.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    white-space: inherit;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix::before,
.clearfix::after {
    content: ' ';
    display: table;
}

.clearfix::after {
    clear: both;
}

.button-rec {
    width: 30px;
    height: 30px;
    font-size: 0;
    background-color: red;
    border: 0;
    border-radius: 35px;
    outline: none;
    cursor: pointer;
}

.notRec {
    background-color: darkred;
}

.Rec {
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes pulse {
    0% {
        box-shadow: 0px 0px 5px 0px rgba(173, 0, 0, 0.3);
    }
    65% {
        box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0.3);
    }
    90% {
        box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0);
    }
}

#container {
    height: 100vh;
    position: relative;
}

.video-viewer {
    transform: initial
}

.video-player-wrapper {
    max-width: 1000px;
    position: relative;
    overflow: hidden;
    background-color: transparent;
}

.toolbar {
    height: 70px;
    position: relative;
    top: -70px;
}

#canvas {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}
